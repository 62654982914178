// Doc Examples
import dynamic from 'next/dynamic'

// import AboutUs from 'components/commercetools-ui/about-us'
// import CancelSuccess from 'components/commercetools-ui/cancel-success'
// import CancellationPolicy from 'components/commercetools-ui/cancellation-policy'
// import ContactUs from 'components/commercetools-ui/contact-us'
// import FAQuestions from 'components/commercetools-ui/faq'
// import CancelOrder from 'components/commercetools-ui/orders/cancel-order'
// import Ewaste from 'components/commercetools-ui/orders/e-waste'
// import ReturnOrReplace from 'components/commercetools-ui/orders/return-replace'
// import PaymentCancel from 'components/commercetools-ui/payment-cancel'
// import PrivacyPolicy from 'components/commercetools-ui/privacy-policy'
// import ReturnPolicy from 'components/commercetools-ui/return-policy'
// import ShippingDelivery from 'components/commercetools-ui/shipping-delivery'
// import TermsOfUse from 'components/commercetools-ui/terms-of-use'
// import AccountDetails from './account/details'
// import AccountLogin from './account/login'
// import AccountOrdersHistory from './account/orders'
// import AccountRegister from './account/register'
// import ResetPassword from './account/reset-password'
// import AccountTest from './account/test'
// import Cart from './cart'
// import Checkout from './checkout'
// import ThankYou from './checkout/thank-you'
// import BlogTastic from './content/amplience-blog'
// import BlogListTastic from './content/amplience-blog-list'
// import CategoryTeasers from './content/category-teasers'
// import ContentstackBlogTastic from './content/contentstack-blog'
// import ContentstackBlogListTastic from './content/contentstack-blog-list'
// import Markdown from './content/markdown'
// import Spacer from './content/spacer'
// import Tile from './content/tile'
// import HelloWorld from './doc-examples/hello-world'
// import SimpleButton from './doc-examples/simple-button'
// import StarWarsCharacterFilter from './doc-examples/star-wars/character-filter'
// import StarWarsCharacterSearch from './doc-examples/star-wars/character-search'
// import StarWarsOpeningCrawl from './doc-examples/star-wars/movies'
//import ContentfulBlog from './doc-examples/contentful/blog';

// import FrequentlyAskedQuestions from './Faq'
// import Hero from './hero'
// import HomepageTastic from './homepage'
// import NewsCardTastic from './news-card'
// import Newsletter from './newsletter'
import NotFound from './not-found'
// import OrderSummaryTastic from './order-summary'
// import ProductInformation from './productInformation'
import ProductDetails from './products/details'
// import ProductList from './products/product-list'
// import SimilarProducts from './products/similar-products'
// import ProductSlider from './products/slider'
// import Showcase from './showcase'
// import StyleGuideTastic from './style-guide'
// import TestimonialTastics from './Testimonial'
// import Wishlist from './wishlist'

export const tastics = {
  // Doc Examples
  // 'example/simple-button': SimpleButton,
  // 'example/hello-world': HelloWorld,
  // 'example/star-wars/movie': StarWarsOpeningCrawl,
  // 'example/star-wars/character-search': StarWarsCharacterSearch,
  // 'example/star-wars/character-filter': StarWarsCharacterFilter,
  //'contentful/blog': ContentfulBlog,
  'commercetools/ui/order-summary': dynamic(() => import('./order-summary')),
  'commercetools/ui/patient-support-consent': dynamic(() => import('./patient-support-consent')),
  // 'commercetools/ui/payment-cancel': PaymentCancel,
  'commercetools/ui/checkout': dynamic(() => import('./checkout')),
  'commercetools/ui/thank-you': dynamic(() => import('./checkout/thank-you')),
  // 'commercetools/ui/cancel-success': CancelSuccess,
  'commercetools/ui/cart': dynamic(() => import('./cart')),
  'commercetools/ui/footer': dynamic(() => import('./footer')),
  'commercetools/ui/header': dynamic(() => import('./header')),
  // 'commercetools/ui/content/amplience-blog': BlogTastic,
  // 'commercetools/ui/content/amplience-blog-list': BlogListTastic,
  // 'commercetools/ui/content/blog': BlogTastic,
  // 'commercetools/ui/content/blog-list': BlogListTastic,
  // 'commercetools/ui/content/contentstack-blog': ContentstackBlogTastic,
  // 'commercetools/ui/content/contentstack-blog-list': ContentstackBlogListTastic,
  'commercetools/ui/content/tile': dynamic(() => import('./content/tile')),
  'commercetools/ui/content/spacer': dynamic(() => import('./content/spacer')),
  'commercetools/ui/content/showcase': dynamic(() => import('./showcase')),
  // 'commercetools/ui/content/markdown': Markdown,
  'commercetools/ui/content/newsletter': dynamic(() => import('./newsletter')),
  'commercetools/ui/products/details': ProductDetails,
  // 'commercetools/ui/products/product-list': ProductList,
  // 'commercetools/ui/products/slider': ProductSlider,
  // 'commercetools/ui/products/similar-products': SimilarProducts,
  // 'commercetools/ui/wishlist': Wishlist,
  // 'commercetools/ui/account/test': AccountTest,
  'commercetools/ui/account/details': dynamic(() => import('./account/details')),
  'commercetools/ui/account/login': dynamic(() => import('./account/login')),
  'commercetools/ui/account/register': dynamic(() => import('./account/register')),
  'commercetools/ui/account/orders': dynamic(() => import('./account/orders')),
  'commercetools/ui/account/reset-password': dynamic(() => import('./account/reset-password')),
  // 'commercetools/ui/content/category-teasers': CategoryTeasers,
  // 'commercetools/ui/content/news-card': NewsCardTastic,
  // 'commercetools/ui/hero': Hero,
  'Product-Information': dynamic(() => import('./productInformation')),
  'orders/cancel-order': dynamic(() => import('components/commercetools-ui/orders/cancel-order')),
  'orders/return-or-replace': dynamic(() => import('components/commercetools-ui/orders/return-replace')),
  'orders/e-waste': dynamic(() => import('components/commercetools-ui/orders/e-waste')),
  'commercetools/ui/cancellation-policy': dynamic(() => import('components/commercetools-ui/cancellation-policy')),
  'commercetools/ui/privacy-policy': dynamic(() => import('components/commercetools-ui/privacy-policy')),
  'commercetools/ui/fa-questions': dynamic(() => import('components/commercetools-ui/faq')),
  'commercetools/ui/shipping-delivery': dynamic(() => import('components/commercetools-ui/shipping-delivery')),
  'commercetools/ui/terms-of-use': dynamic(() => import('components/commercetools-ui/terms-of-use')),
  'commercetools/ui/contact-us': dynamic(() => import('components/commercetools-ui/contact-us')),
  'commercetools/ui/about-us': dynamic(() => import('components/commercetools-ui/about-us')),
  'commercetools/ui/return-refund': dynamic(() => import('components/commercetools-ui/return-policy')),
  testimonial: dynamic(() => import('./Testimonial')),
  Faq: dynamic(() => import('./Faq')),
  'tastic/homepage': dynamic(() => import('./homepage')),
  'tastic/styleguide': dynamic(() => import('./style-guide')),
  'commercetools/ui/email-verification': dynamic(() => import('./email-verification')),
  'commercetools/ui/welcome': dynamic(() => import('./welcome')),
  'commeretools/ui/token-verification': dynamic(() => import('./token-verification')),
  default: NotFound,
}
