import { useEffect, useState } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import Badge from 'components/badge';
import Loader from 'components/commercetools-ui/loader';
import { CHECKOUT } from 'helpers/constants/statisURLs';
import { useFormat } from 'helpers/hooks/useFormat';
import { getReferenceTarget, getTargetProps } from 'helpers/reference';
import ProductImageGallery from './gallery';
import ProductOffer from './offer';
import ProductDetailUSP from './usp';
import useTrack from '../../../../helpers/hooks/useTrack';
import toast from 'react-hot-toast';
import { ACCOUNT_DELETION } from 'helpers/constants/localStorage';
export default function ProductDetail({ tasticData, product, onAddToCart, onAddToWishlist, variant, onChangeVariantIdx, quickBuyEnabled, testDeviceOffer, prodBundles, }) {
    //next/router
    const router = useRouter();
    const { addToCart, viewProduct } = useTrack();
    //i18n messages
    const { formatMessage: formatProductMessage } = useFormat({ name: 'product' });
    const { formatMessage: formatAccountMessage } = useFormat({ name: 'account' });
    //Component states
    const [loading, setLoading] = useState(false);
    const [selectedVariant, setSelectedVariant] = useState(variant);
    useEffect(() => {
        viewProduct(product, variant);
        return () => { };
    }, []);
    const handleAddToCart = async () => {
        setLoading(true);
        addToCart(product, selectedVariant);
        await onAddToCart(selectedVariant, 1);
        router.push(CHECKOUT);
    };
    const onSelectVariant = (variant) => {
        setSelectedVariant(variant);
    };
    const isPrescriptionRequired = !!getReferenceTarget(tasticData?.prescriptionRef) && product?.attributes?.isPrescriptionRequired;
    /**
     * TODO: remove this toast when we get the homepage back
     */
    const showToastForAccountDeletion = () => {
        toast.dismiss();
        toast.success(<>
        <p>{formatAccountMessage({ id: 'account.delete.request.success' })}</p>
        &nbsp;
        <button onClick={() => toast.dismiss()}>&#x2715;</button>
      </>, { position: 'top-right', duration: 30000, style: { marginTop: 'var(--toast-margin-top)' } });
    };
    if (typeof window !== 'undefined' && window.localStorage.getItem(ACCOUNT_DELETION) === '1') {
        showToastForAccountDeletion();
        window.localStorage.removeItem(ACCOUNT_DELETION);
    }
    return (<>
      {loading && <Loader />}
      <div className="PDP bg-gradient-vertical lg:bg-gradient-100">
        <div className="PDP__container">
          <div className="PDP--left">
            {/* product__gallery */}
            <ProductImageGallery images={product?.images} tasticData={tasticData} isPrescriptionRequired={!!isPrescriptionRequired}/>

            {!!tasticData?.imageDisclaimer && (<p className="mt-4 text-center text-xs text-gray-medium">{tasticData?.imageDisclaimer}</p>)}

            {/* product__usp--desktop */}
            {product?.attributes?.isUSPAvailable && !!tasticData?.usp?.length && (<ProductDetailUSP allUsp={tasticData?.usp} uspIndexes={product?.attributes?.uspList} className="product__USPs--desktop"/>)}
          </div>

          <div className="PDP--right">
            <h1 className="product__name mb-5">{product?.name}</h1>

            {/* product__desc */}
            <div className="product__desc mb-8" dangerouslySetInnerHTML={{ __html: product?.description }}/>

            {/* product__desc */}
            <ProductOffer tasticData={tasticData} product={product} testDeviceOffer={testDeviceOffer} prodBundles={prodBundles} onSelectVariant={onSelectVariant}/>
            {/* product__prescription */}

            {!!isPrescriptionRequired && (<div className="mt-10 flex flex-col-reverse items-start gap-4 lg:flex-row lg:items-center lg:justify-between">
                <Link href={getReferenceTarget(tasticData?.prescriptionRef)}>
                  <a {...getTargetProps(tasticData?.prescriptionRef)} className="text-sm text-green-dark underline" data-cy="prescription-link">
                    {tasticData?.prescriptionQuestion}
                  </a>
                </Link>
                <Badge media={tasticData?.prescriptionIcon?.media} label={tasticData?.prescriptionLabel} className="recipe-badge whitespace-nowrap"/>
              </div>)}

            {/* product__CTA */}
            <button data-cy="buy-button" className="product__CTA btn btn-primary align-stretch mt-15 w-full" onClick={handleAddToCart}>
              {formatProductMessage({ id: 'bag.add', defaultMessage: 'Add to bag' })}
            </button>

            {/* product__usp--mobile */}
            {product?.attributes?.isUSPAvailable && !!tasticData?.usp?.length && (<ProductDetailUSP allUsp={tasticData?.usp} uspIndexes={product?.attributes?.uspList} className="product__USPs--mobile"/>)}

            {/* product__footnote */}
            {product?.attributes?.footNote && (<div className="product__footnote" dangerouslySetInnerHTML={{ __html: product?.attributes?.footNote }}/>)}
          </div>
        </div>
      </div>
    </>);
}
