import classNames from "classnames";
import Image from "frontastic/lib/image";
const Badge = ({ src, media, label, className }) => {
    return (<div className={classNames(className, 'btn btn-badge btn-icon')}>
      <div className='icon--wrapper'>
        <Image {...(src
        ? { src: src }
        : { media: media })} objectFit="contain" loading={'eager'} sizes="32px" alt={label}/>
      </div>
      {label}
    </div>);
};
export default Badge;
