import React from 'react';
import { useFormat } from 'helpers/hooks/useFormat';
function Loader() {
    const { formatMessage } = useFormat({ name: 'common' });
    return (<div className="loader-wrapper">
      <span className="loader-icon"></span>
      <p className="loader-font">
        {formatMessage({ id: 'please.wait', defaultMessage: 'We are processing your request' })}
      </p>
    </div>);
}
export default Loader;
