import { useEffect, useMemo } from 'react'
import { useRouter } from 'next/router'
import { Product } from '@Types/product/Product'
import { Variant } from '@Types/product/Variant'
import { Voucherify } from '@Types/voucherify/Voucher'
import ProductDetails, { UIProduct } from 'components/commercetools-ui/products/product-details'
import { ProductDetailsTasticProps } from 'types/pdp'
import { useCart } from 'frontastic'
import { addToWishlist } from 'frontastic/actions/wishlist'

interface ProductDetailsProps {
  data: ProductDetailsTasticProps
}

const ProductDetailsTastic: React.FC<ProductDetailsProps> = ({ data }) => {
  const router = useRouter()
  const { product, testDeviceOffer, bundles }: { product: Product; testDeviceOffer: Voucherify; bundles: Product[] } =
    data?.data?.dataSource

  const currentVariantIdx = useMemo<number>(() => {
    let currentVariantSKU = router.asPath.split('/')[2]
    if (currentVariantSKU.includes('?')) {
      currentVariantSKU = currentVariantSKU.split('?')[0]
    }

    return product?.variants.findIndex(({ sku }) => sku === currentVariantSKU) ?? 0
  }, [product, router.asPath])

  // The master varaint is always the first one
  const variant = useMemo<Variant>(() => product?.variants[0], [product, 0])

  const { addItem } = useCart()

  // const grayFix = (word: string) => (word === 'grey' ? 'gray' : word)

  // const colors = [
  //   ...new Map(
  //     product?.variants?.map((v: Variant) => [
  //       v.attributes?.color?.label,
  //       {
  //         name: v.attributes?.color?.label,
  //         key: v.attributes?.color?.key,
  //         bgColor: `bg-${grayFix(v.attributes?.color?.key)}-500`,
  //         selectedColor: `ring-${grayFix(v.attributes?.color?.key)}-500`,
  //       },
  //     ]),
  //   ).values(),
  // ] as UIColor[]

  // const sizes = [
  //   ...new Map(
  //     product?.variants?.map((v: Variant) => [v.attributes?.commonSize?.label, v.attributes?.commonSize]),
  //   ).values(),
  // ] as UISize[]

  // this maps the entire payload to a component
  // friendly datastructure, so data and presentation
  // stay decoupled.
  // TODO: properly type

  const prod = useMemo<UIProduct>(
    () => ({
      productId: product?.productId,
      name: product?.name,
      _url: product?._url,
      // add variants as well, so we can select and filter
      variants: product?.variants,
      price: variant?.price,
      // rating: 4,
      images: variant?.images?.map((img: string, id: number) => ({
        id: `${variant?.sku}-${id}`,
        src: img,
        alt: variant?.sku,
      })),
      // colors,
      // sizes,
      description: `${product?.description || ''}`,
      attributes: variant?.attributes,
    }),
    [product, variant, bundles],
  )

  const prodBundles = useMemo<UIProduct[]>(
    () =>
      bundles?.map((bundle: Product) => ({
        productId: bundle?.productId,
        name: bundle?.name,
        _url: bundle?._url,
        // add variants as well, so we can select and filter
        variants: bundle?.variants,
        price: bundle?.variants?.[0]?.price,
        // rating: 4,
        images: bundle?.variants?.[0]?.images?.map((img: string, id: number) => ({
          id: `${bundle?.variants?.[0]?.sku}-${id}`,
          src: img,
          alt: bundle?.variants?.[0]?.sku,
        })),
        // colors,
        // sizes,
        description: `${product?.description || ''}`,
        attributes: bundle?.variants?.[0]?.attributes,
      })),
    [product, variant],
  )

  const handleAddToCart = (variant: Variant): Promise<void> => {
    return addItem(variant, 1)
  }

  const handleAddToWishList = () => {
    addToWishlist(variant.sku, 1)
  }

  const handleVariantIdxChange = (idx: number) => {
    // const variant = product?.variants[idx]
    // const url = `${router.asPath.split('/').slice(0, 3).join('/')}/${variant.sku}`
    // router.replace(url, undefined, { shallow: true })
  }

  //For SSR render when going back
  useEffect(() => {
    router.beforePopState(({ as }) => {
      if (as !== router.asPath) {
        router.replace(as, undefined)
        return false
      }

      return true
    })

    return () => router.beforePopState(() => true)
  }, [])

  if (!product || !variant || !prod) return <></>

  return (
    <ProductDetails
      tasticData={data}
      product={prod}
      onAddToCart={handleAddToCart}
      variant={variant}
      onChangeVariantIdx={handleVariantIdxChange}
      onAddToWishlist={handleAddToWishList}
      testDeviceOffer={testDeviceOffer}
      prodBundles={prodBundles}
    />
  )
}

export default ProductDetailsTastic
