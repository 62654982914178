const useTrack = () => {
    const purchase = (order) => {
        gtag('event', 'purchase', {
            transaction_id: order.orderId,
            value: order.sum.centAmount / 100,
            tax: (order.taxed?.taxPortions?.[0]?.amount?.centAmount || 0) / 100,
            shipping: 0,
            currency: order.sum.currencyCode,
            coupon: order.custom?.fields?.discount_codes?.[0] || '',
            items: order.lineItems.map((item, index) => {
                return {
                    item_id: item.variant.sku,
                    item_name: item.name,
                    index,
                    price: item.price.centAmount / 100,
                    quantity: item.count,
                };
            }),
        });
    };
    const viewProduct = (product, variant) => {
        variant = variant ?? product.variants.at(0);
        gtag('event', 'view_item', {
            currency: variant?.price?.currencyCode,
            value: variant?.price?.centAmount / 100,
            items: [{
                    item_id: variant.sku,
                    item_name: product.name,
                    item_category: product.categories?.at(0)?.name,
                }]
        });
    };
    const addToCart = (product, variant) => {
        variant = variant ?? product.variants.at(0);
        gtag('event', 'add_to_cart', {
            currency: variant?.price?.currencyCode,
            value: variant?.price?.centAmount / 100,
            items: [{
                    item_id: variant.sku,
                    item_name: product.name,
                    item_category: product.categories?.at(0)?.name,
                }]
        });
    };
    const beginCheckout = (cart) => {
        gtag('event', 'begin_checkout', {
            currency: cart.sum.currencyCode,
            value: cart.sum.centAmount / 100,
            items: cart.lineItems.map((item, index) => {
                return {
                    item_id: item.variant.sku,
                    item_name: item.name,
                    index,
                    price: item.price.centAmount / 100,
                    quantity: item.count,
                };
            }),
        });
    };
    const addShipping = (cart, shippingMethod) => {
        gtag('event', 'add_shipping_info', {
            currency: cart.sum.currencyCode,
            value: cart.sum.centAmount / 100,
            shipping_tier: shippingMethod.name,
            items: cart.lineItems.map((item, index) => {
                return {
                    item_id: item.variant.sku,
                    item_name: item.name,
                    index,
                    price: item.price.centAmount / 100,
                    quantity: item.count,
                };
            }),
        });
    };
    const addPayment = (cart, paymentMethod) => {
        gtag('event', 'add_payment_info', {
            currency: cart.sum.currencyCode,
            value: cart.sum.centAmount / 100,
            payment_type: paymentMethod,
            items: cart.lineItems.map((item, index) => {
                return {
                    item_id: item.variant.sku,
                    item_name: item.name,
                    index,
                    price: item.price.centAmount / 100,
                    quantity: item.count,
                };
            }),
        });
    };
    const addDiscount = (cart) => {
        gtag('event', 'select_promotion', {
            promotion_id: cart.custom?.fields?.discount_codes?.[0] || '',
            promotion_name: cart.custom?.fields?.discount_codes?.[0] || '',
            coupon: cart.custom?.fields?.discount_codes?.[0] || '',
            items: cart.lineItems.map((item, index) => {
                return {
                    item_id: item.variant.sku,
                    item_name: item.name,
                    index,
                    price: item.price.centAmount / 100,
                    quantity: item.count,
                };
            }),
        });
    };
    return {
        purchase,
        viewProduct,
        addToCart,
        beginCheckout,
        addShipping,
        addPayment,
        addDiscount,
    };
};
export default useTrack;
