import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react'; // eslint-disable-line import/no-unresolved
import { FreeMode, Pagination, Navigation, Thumbs } from 'swiper/modules'; // eslint-disable-line import/no-unresolved
import Image from 'frontastic/lib/image';
import Badge from 'components/badge';
const ProductImageGallery = ({ images, tasticData, isPrescriptionRequired = false }) => {
    const initialSlidesPerViewThumbnails = 3; // Set slidesPerView for thumbnails here
    const [slidesPerViewThumbnails, setSlidesPerViewThumbnails] = useState(initialSlidesPerViewThumbnails);
    const [thumbsSwiper, setThumbsSwiper] = useState(null);
    const swiperRef = React.useRef(null);
    useEffect(() => {
        if (images.length < initialSlidesPerViewThumbnails) {
            setSlidesPerViewThumbnails(images.length);
        }
    }, [images]);
    if (!images || !images?.length) {
        return null;
    }
    const resizedImages = images?.map((image) => {
        const url = image.src;
        const lastDotIndex = url.lastIndexOf('.');
        const largeSrc = `${url.slice(0, lastDotIndex)}-large${url.slice(lastDotIndex)}`;
        const mediumSrc = `${url.slice(0, lastDotIndex)}-medium${url.slice(lastDotIndex)}`;
        const smallSrc = `${url.slice(0, lastDotIndex)}-small${url.slice(lastDotIndex)}`;
        return {
            ...image,
            largeSrc: largeSrc,
            mediumSrc: mediumSrc,
            smallSrc: smallSrc,
        };
    });
    return (<div className="product__gallery">
      {isPrescriptionRequired && (<Badge media={tasticData?.prescriptionIcon?.media} label={tasticData?.prescriptionLabel} className="recipe-badge"/>)}
      {/* In ES, cannot import GLobal CSS so we use CDN */}
      {/* TODO: also use CDN for Swiper css in DE */}
      <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/swiper@11/swiper-bundle.min.css"/>

      {/* swiper--main */}
      <Swiper ref={swiperRef} thumbs={{ swiper: thumbsSwiper }} pagination={{ clickable: true }} modules={[FreeMode, Thumbs, Pagination]} className="swiper--main" breakpoints={{
            0: {
                slidesPerView: 1.2,
                spaceBetween: 10,
                centeredSlides: true,
            },
            1024: {
                slidesPerView: 1,
                spaceBetween: 0,
            },
        }}>
        {resizedImages?.map((image, index) => (<SwiperSlide key={image.id}>
            <span className="absolute inset-0 overflow-hidden">
              <picture>
                <source media="(min-width:700px)" srcSet={image.largeSrc}/>
                <Image src={image.mediumSrc} alt={image?.alt} loading={index == 0 ? 'eager' : 'lazy'} className="h-full w-full object-center"/>
              </picture>
            </span>
          </SwiperSlide>))}
      </Swiper>

      {/*  swiper--thumbnails */}
      <div className="thumbnail-container">
        <div className="thumbnail-navigation swiper-button-prev"/>
        <Swiper style={{ '--thumbnail-container-height': `calc((100px + 24px) * ${slidesPerViewThumbnails} - 24px)` }} onSwiper={setThumbsSwiper} spaceBetween={24} slidesPerView={slidesPerViewThumbnails} freeMode={true} direction={'vertical'} modules={[FreeMode, Thumbs, Navigation]} watchSlidesProgress={true} className="swiper--thumbnails" navigation={{
            prevEl: '.thumbnail-navigation.swiper-button-prev',
            nextEl: '.thumbnail-navigation.swiper-button-next',
        }}>
          {resizedImages?.map((image) => (<SwiperSlide key={image.id}>
              <span className="absolute inset-0 overflow-hidden">
                <Image src={image.smallSrc} alt={image?.alt} loading={'eager'} className="h-full w-full object-center"/>
              </span>
            </SwiperSlide>))}
        </Swiper>
        <div className="thumbnail-navigation swiper-button-next"/>
      </div>
    </div>);
};
export default ProductImageGallery;
