export const STATIC_PRESCRIPTION_UPLOAD = '/checkout/prescription-upload';
export const STATIC_CHECKOUT_ORDER = '/checkout/order';
export const STATIC_CHECKOUT_ORDER_SUMMARY = 'checkout/orderSummary?orderNumber=';
export const STATIC_ORDER_CANCELLATION = '/checkout/orderCancellation';
export const STATIC_ORDER_CANCELLATION_REASONS = '/custom-objects?where=container%3D%22order-cancel-reason%22';
export const STATIC_ORDER_RETURN_REASONS = '/custom-objects?where=container%3D"order-return-reason"';
export const STATIC_ORDER_RETURN_CITIES = '/custom-objects?where=container%3D"store-city"';
export const STATIC_ORDER_RETURN_STORES = 'custom-objects?where=container%3D';
export const STATIC_ORDER_RETURN = '/checkout/return';
export const STATIC_CHECKOUT_DOWNLOAD_INVOICE = 'checkout/invoiceDownload?orderId=';
export const STATIC_CONTACT_US = 'notifications/contactUs';
export const STATIC_PAYPAL_ACCESSTOKEN = 'checkout/paypal/accessToken';
export const CHECKOUT_STEP_PARAM = 'step';
export const HOME = '/productos/nerivio';
export const CHECKOUT = '/checkout';
export const CART = '/checkout?step=cart';
export const MY_ACOUNT = {
    PROFILE: '/account',
    ADDRESSES: '/account#addresses',
    ORDERS: '/account#orders',
    SUPPORT: '/account#support',
};
export const EMAIL_VERIFICATION = '/email-verification';
export const WELCOME_PAGE = '/welcome';
